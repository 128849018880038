/**
 Common renderers for ag-grid
 */
import React from 'react';
import COLORS from "../colors";
import {DB_DATE_TIME_FORMAT, getUserDateTime} from "../../utils/date";
import {t} from "../../translation";
import CopyToClipboard from "../widgets/copy-to-clipboard";
import {isDistributor} from "../../auth/guard/check";

const commonCellRenderers = {
    centerText: (data) => {
        return <div style={{textAlign: 'center'}}>{t(data.value)}</div>;
    },
    centerTextUpper: (data) => {
        return commonCellRenderers.centerText({value: t(!!data.value? data.value.toUpperCase(): '')});
    },
    boldText: (data) => {
        return <div style={{textAlign: 'center', fontWeight: 'bold'}}>{t(data.value)}</div>;
    },
    centerTextWithTitle: (data) => {
        return <div style={{textAlign: 'center'}} title={t(data.value)}>{t(data.value)}</div>;
    },
    centerTextWithCopyToClipboard: (data) => {
        if(data.value === null)
        {
            return '';
        }
        return commonCellRenderers.centerText({value: <span><span className='me-1'>{data.value}</span> <CopyToClipboard content={data.value}/></span>});
    },
    booleanRenderer: (node) => {
        let value = <span style={{color: COLORS.GREEN}}>{t('YES')}</span>;
        if(!node.value)
        {
            value = t('NO');
        }
        return commonCellRenderers.boldText({value});
    },
    /**
     * Without colors
     */
    plainBooleanRenderer: (node) => {
        return commonCellRenderers.centerText({value: t(!node.value? 'NO': 'YES')});
    },
    arrayRenderer: (node) => {
        if(Array.isArray(node.value))
        {
            return commonCellRenderers.centerText({value: node.value.join(', ')});
        }
        return commonCellRenderers.centerText({value: ''});
    },
    userDateTime: (node) => {
        return commonCellRenderers.centerText({value: getUserDateTime(node.value) ?? ''});
    },
    userDbDateTime: (node) => {
        return commonCellRenderers.centerText({value: getUserDateTime(node.value, DB_DATE_TIME_FORMAT) ?? ''});
    },
    company: (node) => {
        if(node.value === null)
        {
            return '';
        }
        const label = <span className="me-1">{node.value.name} ({node.value.email})</span>;
        const elem = <span>
            {label}
            <CopyToClipboard content={`${node.value.name} (${node.value.email})`}/>
        </span>;
        return commonCellRenderers.centerText({value: elem});
    },
    userEmail: (node) => {
        //attract attention to blocked users!
        let blockedUserStyle = {};
        if(isDistributor())
        {
            if(node.data.state === 'blocked')
            {
                blockedUserStyle.color = COLORS.RED;
                blockedUserStyle.fontWeight = 'bold';
            }
            if(!isDistributor(['sign-in-as-users']))
            {
                const noSignInLink = <span>
                    <span style={blockedUserStyle}>{node.value}</span>
                    <CopyToClipboard content={node.value}/>
                </span>;
                return commonCellRenderers.centerText({value: noSignInLink});
            }
        }
        const link = <span>
            <a className='me-1' href={`/user-impersonation-token?userID=${node.data.id}`} target="_blank" rel="noreferrer noopener" style={blockedUserStyle}>{node.value}</a>
            <CopyToClipboard content={node.value}/>
        </span>;
        return commonCellRenderers.centerText({value: link});
    },
    companyUserAssignment: (node) => {
        let value = 'All users';
        if(node.value !== null)
        {
            value = `${node.value.length} ${node.value.length === 1? 'company': 'companies'}`;
        }
        return commonCellRenderers.centerText({value});
    },
    isRegisteredInWASL: (node) => {
        return commonCellRenderers.booleanRenderer({value: !!node.value?.length && node.value.find(r => r.activityType === 'DEFAULT') !== undefined});
    },
    isRegisteredInSFDA: (node) => {
        return commonCellRenderers.booleanRenderer({value: !!node.value?.length && node.value.find(r => r.activityType === 'SFDA') !== undefined});
    },
    isRegisteredInTowCar: (node) => {
        return commonCellRenderers.booleanRenderer({value: !!node.value?.length && node.value.find(r => r.activityType === 'TOW_CAR') !== undefined});
    },
    isRegisteredInSpecialityTransport: (node) => {
        return commonCellRenderers.booleanRenderer({value: !!node.value?.length && node.value.find(r => r.activityType === 'SPECIALITY_TRANSPORT') !== undefined});
    },
    isRegisteredInBusRental: (node) => {
        return commonCellRenderers.booleanRenderer({value: !!node.value?.length && node.value.find(r => r.activityType === 'BUS_RENTAL') !== undefined});
    },
    isRegisteredInEducationalTransport: (node) => {
        return commonCellRenderers.booleanRenderer({value: !!node.value?.length && node.value.find(r => r.activityType === 'EDUCATIONAL_TRANSPORT') !== undefined});
    },
    isRegisteredInInternationalTransport: (node) => {
        return commonCellRenderers.booleanRenderer({value: !!node.value?.length && node.value.find(r => r.activityType === 'INTERNATIONAL_TRANSPORT') !== undefined});
    },
    //checks for validity of trackers, drivers, etc.
    isEntityRegisteredInWASL: (node) => {
        //trackers have the nested type of user {company}, while drivers carry company info directly, due to the user being nullable on drivers
        if((node.data?.user?.company?.wasl ?? node.data?.company?.wasl ?? []).find(r => r.activityType === 'DEFAULT') === undefined)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        return commonCellRenderers.isRegisteredInWASL(node);
    },
    isEntityRegisteredInSFDA: (node) => {
        if((node.data?.user?.company?.wasl ?? node.data?.company?.wasl ?? []).find(r => r.activityType === 'SFDA') === undefined)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        return commonCellRenderers.isRegisteredInSFDA(node);
    },
    isEntityRegisteredInTowCar: (node) => {
        if((node.data?.user?.company?.wasl ?? node.data?.company?.wasl ?? []).find(r => r.activityType === 'TOW_CAR') === undefined)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        return commonCellRenderers.isRegisteredInTowCar(node);
    },
    isEntityRegisteredInSpecialityTransport: (node) => {
        if((node.data?.user?.company?.wasl ?? node.data?.company?.wasl ?? []).find(r => r.activityType === 'SPECIALITY_TRANSPORT') === undefined)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        return commonCellRenderers.isRegisteredInSpecialityTransport(node);
    },
    isEntityRegisteredInBusRental: (node) => {
        if((node.data?.user?.company?.wasl ?? node.data?.company?.wasl ?? []).find(r => r.activityType === 'BUS_RENTAL') === undefined)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        return commonCellRenderers.isRegisteredInBusRental(node);
    },
    isEntityRegisteredInEducationalTransport: (node) => {
        if((node.data?.user?.company?.wasl ?? node.data?.company?.wasl ?? []).find(r => r.activityType === 'EDUCATIONAL_TRANSPORT') === undefined)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        return commonCellRenderers.isRegisteredInEducationalTransport(node);
    },
    isEntityRegisteredInInternationalTransport: (node) => {
        if((node.data?.user?.company?.wasl ?? node.data?.company?.wasl ?? []).find(r => r.activityType === 'INTERNATIONAL_TRANSPORT') === undefined)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        return commonCellRenderers.isRegisteredInInternationalTransport(node);
    },
    isWASLValid: (node) => {
        return commonCellRenderers.waslValidityByActivityType(node, 'DEFAULT');
    },
    isSFDAValid: (node) => {
        return commonCellRenderers.waslValidityByActivityType(node, 'SFDA');
    },
    isTowingCarsValid: (node) => {
        return commonCellRenderers.waslValidityByActivityType(node, 'TOW_CAR');
    },
    isSpecialityTransportValid: (node) => {
        return commonCellRenderers.waslValidityByActivityType(node, 'SPECIALITY_TRANSPORT');
    },
    isBusRentalValid: (node) => {
        return commonCellRenderers.waslValidityByActivityType(node, 'BUS_RENTAL');
    },
    isEducationalTransportValid: (node) => {
        return commonCellRenderers.waslValidityByActivityType(node, 'EDUCATIONAL_TRANSPORT');
    },
    isInternationalTransportValid: (node) => {
        return commonCellRenderers.waslValidityByActivityType(node, 'INTERNATIONAL_TRANSPORT');
    },
    /**
     * Companies, trackers, etc. where applicable
     */
    waslValidityByActivityType: (node, activityType) => {
        const record = (node.value ?? []).find(r => r.activityType === activityType);
        if(!record)
        {
            return commonCellRenderers.centerText({value: 'N/A'});
        }
        if(record.isValid)
        {
            return commonCellRenderers.centerText({value: <span style={{color: COLORS.GREEN}}>Yes, last checked {getUserDateTime(record.validityLastChecked)}</span>});
        }
        else if(record.isValid === false)
        {
            return commonCellRenderers.centerText({value: <span style={{color: COLORS.RED}}>No, {record.rejectionReason ?? 'no reason given yet!'}, last checked {getUserDateTime(record.validityLastChecked)}</span>})
        }
        return '';
    },
    coordinates: (node) => {
        if(!node.value)
        {
            return '';
        }
        return commonCellRenderers.centerText({value: `${node.value.lat}, ${node.value.lng}`});
    },
    googleMapsLink: (node) => {
        if(!node.value)
        {
            return '';
        }
        const link = <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/?q=${node.value.lat},${node.value.lng}`}>{node.value.lat},{node.value.lng}</a>;
        return commonCellRenderers.centerText({value: link});
    },
    notificationChannels: (node) => {
        const value = <div style={{fontSize: '18px'}}>
            {
                !!node.value?.web &&
                <i className="bx bx-globe" title={t('Web')}/>
            }
            {
                !!node.value?.mobile &&
                <i className="bx bx-mobile" title={t('Mobile')}/>
            }
            {
                !!node.value?.email &&
                <i className="bx bx-envelope" title={t('Email')}/>
            }
        </div>;
        return commonCellRenderers.centerText({value})
    },
    customFields: (node) => {
        const fieldID = /\d+$/.exec(node.colDef.field)?.[0];
        if(fieldID === undefined)
        {
            return '';
        }
        const value = node.data.customFieldsData?.find(r => r.fieldID === fieldID)?.value ?? '';
        return commonCellRenderers.centerText({value});
    },
};

export default commonCellRenderers;